export function domReady(callback) {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', callback);
    } else {
        callback();
    }
}

export function forEach(array, callback) {
    [].forEach.call(array, callback);
}

export function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value)
}

export function getDomainWithoutSubdomain(url) {
    var urlParts = new URL(url).hostname.split('.');
    return urlParts.slice(0).slice(-(urlParts.length === 4 ? 3 : 2)).join('.');
}
