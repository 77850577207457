import cookieStorage from './CookieStorage';
import CookieNotice from './CookieNotice';
import cookieNoticeCheckboxes from './CookieNoticeCheckboxes';
import {domReady} from './Tools';

let cookieNotice;

if (window.cookieNoticeConfig) {
  cookieNotice = new CookieNotice(window.cookieNoticeConfig);
  domReady(function () {
    cookieNoticeCheckboxes.init(cookieNotice);

    cookieNotice.registerHit();

    if (cookieNotice.shouldShowOverlay() === true) {
      showOverlay();
    } else {
      cookieNotice.processCallbacks();
    }

    // Add a cookieNotice listener for click events, continue the script when the clicked element has a data-attribute
    // called `data-cookie-consent`. data-cookie-consent may contain a number (the consentLevel), or a comma
    // separated list of flags (e.g. data-cookie-consent="1,2,4" or data-cookie-consent="TRACKING,EXTERNAL".
    document.addEventListener('click', function (event) {
      var matches = event.target.matches ? event.target.matches('[data-cookie-consent]') : event.target.msMatchesSelector('[data-cookie-consent]');
      if (matches) {
        event.preventDefault();

        var consentValue = event.target.getAttribute('data-cookie-consent');
        var items = consentValue.split(',');
        if (items.length > 0) {
          cookieNotice.addConsentFor(items);
        }

        var noticeElement = document.getElementById(cookieNotice.config.overlayElementId);
        if (noticeElement) {
          noticeElement.classList.remove('cookieNotice--active');
          noticeElement.addEventListener('transitionend', function () {
            if (noticeElement.parentNode) {
              noticeElement.parentNode.removeChild(noticeElement);
            }
          });
        }
      }
    }, false);

    function showOverlay() {
      var parser = new DOMParser(),
        template = cookieNotice.getNotificationElementTemplate(),
        doc = parser.parseFromString(template, 'text/html'),
        noticeElement = doc.getElementById(cookieNotice.config.overlayElementId);

      document.body.prepend(noticeElement);

      window.setTimeout(function () {
        noticeElement.classList.add('cookieNotice--active');
      }, 500);

      var agreeButton = noticeElement.querySelector('.agree')
      if (agreeButton) {
        agreeButton.addEventListener('click', function () {
          window.dataLayer.push({'event': 'cookieConsent-agree'})
        })
      }

      var disagreeButton = noticeElement.querySelector('.disagree')
      if (disagreeButton) {
        disagreeButton.addEventListener('click', function () {
          window.dataLayer.push({'event': 'cookieConsent-disagree'})
        })
      }

      var expandStatement = noticeElement.querySelector('.statement_expand');
      if (expandStatement) {
        expandStatement.addEventListener('click', function (event) {
          var detailElement = noticeElement.querySelector('.statement_detail');

          if (detailElement) {
            event.preventDefault();
            detailElement.classList.add('statement_detail--active');
          }
        });
      }
    }
  });
}

window.cookieNotice = cookieNotice;

export default cookieNotice;
