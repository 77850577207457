export default {

    /**
     * Create a cookie with the given cookieConfig.
     * @param {Object} cookieConfig
     * @param {string} cookieConfig.name - required
     * @param {string|number} cookieConfig.value - required
     * @param {Date} cookieConfig.expires - optional
     * @param {string} [/] cookieConfig.path - optional
     */
    create: function (cookieConfig) {
        // Require a name + value to be set
        if (cookieConfig.hasOwnProperty('name') === true || cookieConfig.hasOwnProperty('value') === true) {
            // Create an array that contains all cookie parts. The first key always contains 'name=value'
            var parts = [cookieConfig.name + '=' + JSON.stringify(cookieConfig.value)];

            // If an expires property is set, append it to the array of items
            if (cookieConfig.hasOwnProperty('expires')) {
                parts.push('expires=' + cookieConfig.expires.toUTCString());
            }

            // Add the path if set, else add path=/ to the list of cookie parts
            if (cookieConfig.hasOwnProperty('path')) {
                parts.push('path=' + cookieConfig.path);
            } else {
                parts.push('path=/');
            }

            if (cookieConfig.hasOwnProperty('domain')) {
                parts.push('domain=' + cookieConfig.domain);
            }

            // Set the cookie, join the cookie parts with a ; and a <space>
            document.cookie = parts.join('; ');
        }
    },
    read: function (name) {
        var nameEQ = name + '=',
            cookieArray = document.cookie.split(';'),
            currentCookie,
            value,
            i;

        for (i = 0; i < cookieArray.length; i += 1) {
            currentCookie = cookieArray[i];
            while (currentCookie.charAt(0) === ' ') {
                currentCookie = currentCookie.substring(1, currentCookie.length);
            }

            if (currentCookie.indexOf(nameEQ) === 0) {
                value = currentCookie.substring(nameEQ.length, currentCookie.length);
            }
        }

        if (value) {
            value = JSON.parse(value);
        }

        return value;
    }
}
