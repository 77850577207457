import {forEach, isNumeric, getDomainWithoutSubdomain} from './Tools';
import cookieStorage from './CookieStorage';

window.dataLayer = window.dataLayer || [];

// Most of these variables will be filled in the init() function
const defaultConfig = {
    storage: cookieStorage,
    flags: {},
    labels: {},
    defaultCookieLevel: 1,
    consentedCookieName: '__cookieNotice_consent',
    hitCountCookieName: '__cookieNotice_hit',
    hitsBeforeImplicitConsent: -1,
    dontShowOverlay: false,
    showDisagreeButton: true,
    expiresInMonths: 12,
    domain: getDomainWithoutSubdomain(window.location),
    overlayElementId: 'cookieNotice',
    disagreeButtonTemplate: '<a role="button" href="{cookiePolicyDetailPage}" class="disagree" data-cookie-consent="{defaultCookieLevel}">{label.disagree}</a>',
    notificationElementTemplate:
        '<div role="dialog" aria-labelledby="cookie-title" aria-describedby="cookie-description" class="cookieNotice" id="{overlayElementId}">' +
            '<div class="contents">' +
                '<div class="statement">' +
                    '<p id="cookie-title">{label.statement_short} <a href="{cookiePolicyDetailPage}" class="statement_expand">{label.statement_expand}</a></p>' +
                    '<p id="cookie-description" class="statement_detail">{label.statement_detail} <a href="{cookiePolicyDetailPage}">{label.link_policy}</a></p>' +
                '</div>' +
                '<div class="cookieButtons">' +
                    '<a role="button" href="{cookiePolicyDetailPage}" class="agree CookiesOK" data-cookie-consent="{consentMax}">{label.agree}</a>' +
                    '{disagreeButton}' +
                '</div>' +
            '</div>' +
        '</div>'
};

// Start typedefs for jsdoc (http://usejsdoc.org/)
//

/**
 * The function to be called when consent is given for the CallbackItem type(s)
 * @callback consentCallback
 */

/**
 @typedef CallbackItem
 @type {object}
 @property {number|number[]} type - One or more flag numbers
 @property {consentCallback} callback - Callback to run
 @property {boolean} [executed=false] - True when the callback has been executed
 @property {boolean} [executeAlways=false] - executeAlways - When true, the consentCallback will _always_ be executed during callback processing, even when the executed bool is true/
 */

//
// End typedefs for jsdoc (http://usejsdoc.org/)
//

export default class {

    /**
     * @param {object} config - Overrides for the default configution
     * @param {object} config.storage - Object that takes care of data persistence. Must have a creat() and a read() method.
     * @param {object<string, number>} config.flags - Key/Value storage of cookieflags, e.g. {BASIC: 1, TRACKING: 2}. Name (key) must be in uppercase, value must be an integer
     * @param {object} config.labels - Object that contains all labels for the notificationElementTemplate
     * @param {string} config.defaultCookieLevel - Default cookie consent level
     * @param {string} config.consentedCookieName - Cookie name for setting the given consent
     * @param {string} config.hitCountCookieName - Cookie name for setting the number of hits
     * @param {number} [config.hitsBeforeImplicitConsent=-1] - After this number of hits, the user automatically consents to cookies. When the value is -1, the hitcount is disabled.
     * @param {boolean} [config.showDisagreeButton=true] - Show a disagree button in the cookie notification popup
     * @param {string} config.disagreeButtonTemplate - HTML-template for the disagree button
     * @param {string} config.notificationElementTemplate - HTML-template for the notification popup
     * @param {CallbackItem[]} config.consentChangeCallbacks - List of CallbackItems
     */
    constructor(config) {
        this.config = Object.assign({}, defaultConfig, config);
        this.state = {
            consentLevel: [this.config.defaultCookieLevel],
            allConsentLevels: [],
            hitCount: 0,
            callbacks: []
        };

        // Calculate the level where someone agrees to all cookies, by adding all cookie.flag values to the allConsentLevels array
        Object.keys(this.config.flags).forEach(flagName => {
            this.state.allConsentLevels.push(this.config.flags[flagName]);
        });

        // Try to get the consent value from the cookie. If it returnes a falsy value, use the default consentLevel
        const consentedCookieValue = this.config.storage.read(this.config.consentedCookieName);
        if (consentedCookieValue) {
            this.state.consentLevel = consentedCookieValue;
        }

        const hitCountCookieValue = this.config.storage.read(this.config.hitCountCookieName);
        if (this.config.hitsBeforeImplicitConsent > -1 && hitCountCookieValue !== undefined) {
            // Try to get the value from the hitCountCookie. When a falsy value is returned, use 0 as hitcount
            this.state.hitCount = hitCountCookieValue;
        }

        // Store the global consentChangeCallbacks in the `callbacks` variable. A bit later in this script, the
        // consentChangeCallbacks from the (new)Config objects will be overwritten -> it'll become a new object. This object will only contain
        // a function `push`, which will automatically call the addCallback function after pushing an item
        if (config.consentChangeCallbacks) {
            this.state.callbacks = config.consentChangeCallbacks;
        }

        this.config.consentChangeCallbacks = config.consentChangeCallbacks = {
            push: this.addCallback.bind(this)
        };
    }

    /**
     * Add a callback item to the list of callbacks and process the callback list
     * @param {CallbackItem} item
     */
    addCallback(item) {
        this.state.callbacks.push(item);
        this.processCallbacks();
    }

    /**
     * Indicates whether the user already made a consent choice.
     * @returns {boolean}
     */
    shouldShowOverlay() {
        return this.config.storage.read(this.config.consentedCookieName) === undefined
            && (this.config.hitsBeforeImplicitConsent === -1 || this.state.hitCount < this.config.hitsBeforeImplicitConsent) && this.config.dontShowOverlay === false
    }

    /**
     * Indicates whether the use has given consent for the given flag(s)
     * @param {(number|number[]|string|string[])} flag - One or more flag names or flag values
     * @returns {boolean} - Returns true when all given flag values are consented, otherwise false is returned
     */
    hasConsentedTo(flag) {
        let consented = false;

        if (isNumeric(flag)) {
            // When the flag is a number, check whether the consentLevel array contain the flag value
            consented = this.state.consentLevel.indexOf(parseInt(flag, 10)) > -1;
        } else if (typeof flag === 'string' && flag.match(/^[A-Z]+$/)) {
            // If the flag is a flag name (uppercase string), retrieve the corresponding value from the flag config
            // and then check the consentLevel array contains the flag value
            consented = this.state.consentLevel.indexOf(this.config.flags[flag]) > -1;
        } else if (Array.isArray(flag)) {
            // If the flag param is an array, loop through the array and check for every item whether consent has been given
            let allConsented = true;
            flag.forEach(value => {
                if (allConsented !== false) {
                    allConsented = this.hasConsentedTo(value);
                }
            });

            consented = allConsented;
        }

        return consented;
    }

    /**
     * Add consent for 1 or more flags
     * @param {(number|number[]|string|string[])} flags - Can be a flag id, name or an array of id's/names
     */
    addConsentFor(flags) {
        if (isNumeric(flags) || typeof flags === 'string') {
            flags = [flags];
        }

        flags.forEach(level => {
            if (!level) {
                return;
            }

            if (!isNumeric(level) && level.match(/^[A-Z]+$/)) {
                level = this.config.flags[level];
            }

            level = parseInt(level, 10);

            if (this.hasConsentedTo(level) === false) {
                this.state.consentLevel.push(level);
            }
        });

        this.persist();
        this.processCallbacks();
    }

    /**
     * Withdraw all given consent
     */
    removeConsent() {
        this.state.consentLevel = [this.config.defaultCookieLevel];
        this.persist();
    }

    /**
     * Returns the notification element template, with all placeholders replaced by the right content
     * @returns {string}
     */
    getNotificationElementTemplate() {
        // Replace the placeholders from the notificationElementTemplate with the new content
        var template = this.config.notificationElementTemplate
            .replace(/{overlayElementId}/g, this.config.overlayElementId)
            .replace(/{disagreeButton}/g, (this.config.showDisagreeButton ? this.config.disagreeButtonTemplate : ''))
            .replace(/{cookiePolicyDetailPage}/g, decodeURIComponent(this.config.cookiePolicyDetailPage))
            .replace(/{defaultCookieLevel}/g, this.config.defaultCookieLevel)
            .replace(/{consentMax}/g, this.state.allConsentLevels.join(','));

        // Replace all {label.NAME} placeholders with values from the config.labels array
        // e.g. {label.NAME} will be replaced by config.labels['NAME']
        Object.keys(this.config.labels).forEach(labelName => {
            template = template.replace(new RegExp('{label.' + labelName + '}', 'g'), decodeURIComponent(this.config.labels[labelName]));
        });

        return template;
    }

    /**
     * Register a hit and store it in the hitCount cookie
     * When the implicit consent hit count is reached, consent will be given for all cookies
     */
    registerHit() {
        if (this.config.hitsBeforeImplicitConsent > -1 && this.shouldShowOverlay()) {
            this.state.hitCount += 1;

            this.config.storage.create({
                name: this.config.hitCountCookieName,
                value: this.state.hitCount,
                domain: this.config.domain
            });

            if (this.state.hitCount >= this.config.hitsBeforeImplicitConsent) {
                this.addConsentFor(this.state.allConsentLevels);
            }
        }
    }

    /**
     * Process all items in the `callbacks` var that don't have an `executed` property, or that should aways be executed
     */
    processCallbacks() {
        // Only select the callbackItems what have not been executed earlier, or that always should be executed
        var filteredCallbacks = this.state.callbacks.filter(cb => {
            return !cb.executed || cb.executeAlways === true;
        });

        // Only execute the callback when the user has given consent for the specific type.
        filteredCallbacks.forEach(cb => {
            if (typeof cb === 'function') {
                console.warn('Items in `consentChangeCallbacks` should be objects, not functions');
            } else {
                if (this.hasConsentedTo(cb.type)) {
                    if (typeof cb.callback === 'function') {
                        cb.callback.call(this);
                        cb.executed = true;
                    } else {
                        console.warn('One of the CookieNotice consentChangeCallbacks doesn\'t have a callback function.');
                    }
                }
            }
        });
    }

    /**
     * Store the consentLevel in the storage
     */
    persist() {
        var expires = new Date();
        expires.setFullYear(expires.getFullYear(), expires.getMonth()+ this.config.expiresInMonths);

        this.config.storage.create({
            name: this.config.consentedCookieName,
            value: this.state.consentLevel,
            domain: this.config.domain,
            expires: expires
        });
    }
};
