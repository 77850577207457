import {domReady, forEach} from './Tools';

var updateCheckboxes = function (cookieNotice, checkboxes) {
    forEach(checkboxes, function (checkbox) {
        checkbox.checked = cookieNotice.hasConsentedTo(checkbox.value);
    });
};

export default {

    init: function (cookieNotice) {
        var noticeContainers = document.querySelectorAll('.cookieNoticeWarning');

        if (!noticeContainers) {
            return;
        }

        forEach(noticeContainers, function (container) {

            var checkboxes = container.querySelectorAll('input[type="checkbox"]');
            updateCheckboxes(cookieNotice, checkboxes);

            var submit = container.querySelector('.cookieNoticeWarning-submit');
            if (submit) {
                submit.addEventListener('click', function (event) {
                    event.preventDefault();

                    cookieNotice.removeConsent();

                    var consentList = [];
                    forEach(checkboxes, function (checkbox) {
                        if (checkbox.checked) {
                            consentList.push(checkbox.value);
                        }
                    });

                    cookieNotice.addConsentFor(consentList);

                    var noticeElement = container.querySelector('.cookieNoticeWarning-message');
                    if (noticeElement) {
                        noticeElement.innerText = noticeElement.getAttribute('data-text');

                        setTimeout(function () {
                            noticeElement.innerText = '';
                        }, 4000);
                    }

                    window.dataLayer.push({'event': 'cookiePreferences-save'});
                });
            }

            var submitAll = container.querySelector('.cookieNoticeWarning-submitAll');
            if (submitAll) {
                submitAll.addEventListener('click', function (event) {
                    event.preventDefault();

                    forEach(checkboxes, function (checkbox) {
                        if (!checkbox.checked ) {
                            checkbox.checked = true;
                        }
                    });

                    submit.click();
                });
            }

            // Add a consentChangeCallback so when the consent level changes, all checkboxes are updated
            cookieNotice.addCallback({
                type: 1,
                callback: function () {
                    updateCheckboxes(cookieNotice, checkboxes);
                },
                executeAlways: true
            });
        });
    }
}
